import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex space-x-5 items-end mb-10" }
const _hoisted_2 = { class: "w-3/5 break-words" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormInput, {
      class: "mb-7",
      label: _ctx.$t('form.email'),
      type: "email",
      disabled: _ctx.disabledEmail,
      model: _ctx.form.email,
      "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.email = $event)),
      field: _ctx.form.email,
      hasError: _ctx.v$.form.email.$error,
      error: _ctx.v$.form.email.$errors[0]
    }, null, 8, ["label", "disabled", "model", "field", "hasError", "error"]),
    _createVNode(_component_FormInput, {
      class: "mb-7",
      label: _ctx.$t('form.password'),
      type: "password",
      model: _ctx.form.password,
      "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.password = $event)),
      hasError: _ctx.v$.form.password.$error,
      error: _ctx.v$.form.password.$errors[0]
    }, null, 8, ["label", "model", "hasError", "error"]),
    _createVNode(_component_FormInput, {
      class: "mb-7",
      label: _ctx.$t('form.password_repeat'),
      type: "password",
      model: _ctx.form.confirmPassword,
      "onUpdate:model": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.confirmPassword = $event)),
      hasError: _ctx.v$.form.confirmPassword.$error,
      error: _ctx.v$.form.confirmPassword.$errors[0],
      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.v$.form.confirmPassword.$touch()))
    }, null, 8, ["label", "model", "hasError", "error"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormInput, {
        class: "w-6/12",
        label: _ctx.$t('form.email_code'),
        type: "text",
        model: _ctx.form.emailCode,
        "onUpdate:model": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.emailCode = $event)),
        hasError: _ctx.v$.form.emailCode.$error,
        error: _ctx.v$.form.emailCode.$errors[0]
      }, null, 8, ["label", "model", "hasError", "error"]),
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.sendEmailCode && _ctx.sendEmailCode(...args))),
        class: "btn primary w-6/12"
      }, _toDisplayString(_ctx.$t("form.send_code")), 1)
    ]),
    _createVNode(_component_FormCheckbox, {
      id: "registrationTermsCheckbox",
      model: _ctx.form.termsCheckbox,
      "onUpdate:model": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.termsCheckbox = $event)),
      hasError: _ctx.v$.form.termsCheckbox.$error,
      checkboxLabel: _ctx.$t('form.agree_error'),
      error: _ctx.$t('form.agree_error')
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t("form.agree")) + " ", 1),
          _createElementVNode("a", {
            class: "btn inline secondary link",
            href: _ctx.$t('form.terms_url'),
            target: "_blank"
          }, _toDisplayString(_ctx.$t("form.terms_text")), 9, _hoisted_3)
        ])
      ]),
      _: 1
    }, 8, ["model", "hasError", "checkboxLabel", "error"]),
    _createElementVNode("button", {
      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
      class: "mt-5 w-full btn secondary flex-1"
    }, _toDisplayString(_ctx.$t("continue")), 1)
  ], 64))
}