
import { Options, Vue } from "vue-class-component";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import RegistrationForm from "@/components/forms/RegistrationForm.vue";
import router from "@/router";

@Options({
  components: {
    SectionTitle,
    RegistrationForm,
    LeftStepBar,
  },

  created() {
    var params = new URLSearchParams();
    params.append("scope", "onboarding");
    params.append("user", "onboarding");
    params.append("pass", "6N9Xebc0i!Nn01%*G2d5");

    this.$store.dispatch("onboarding/setAuthToken", { params });

    if (this.$route.query.ref || localStorage.getItem("referral")) {
      let token = this.$route.query.ref || localStorage.getItem("referral");
      console.log('Token:', token);
      try {
        if (!localStorage.getItem("referral") || this.$route.query.ref) {
          const decodedTokenPayload = JSON.parse(atob(token.split('.')[1]));
          console.log('Decoded JWT Payload:', decodedTokenPayload);

          // Check if the token is expired
          const expirationTimestamp = Number(decodedTokenPayload.exp) * 1000; // Convert seconds to milliseconds
          console.log('Expiration Timestamp:', expirationTimestamp);
          const currentTimestamp = Date.now();

          if (expirationTimestamp <= currentTimestamp) {
            console.log('Token has expired. Removing from storage.');
            localStorage.removeItem('referral');
            localStorage.removeItem('referral_exp');
          } else {
            console.log('Token is valid.');
            localStorage.setItem('referral', decodedTokenPayload.sub);
            localStorage.setItem('referral_exp', decodedTokenPayload.exp);
          }
        }
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    }
  }



})
export default class Registration extends Vue {}
