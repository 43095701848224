
import useVuelidate from "@vuelidate/core";
import { required, email } from "@/utils/i18n-validators";
import {
  helpers,
  sameAs,
  minLength,
  maxLength,
  alphaNum,
} from "@vuelidate/validators";
import { Options, Vue } from "vue-class-component";
import FormInput from "@/shared/components/FormInput.vue";
import FormCheckbox from "@/shared/components/FormCheckbox.vue";
import { mapGetters } from "vuex";

@Options({
  data() {
    return {
      v$: useVuelidate(),
      formSubmitted: false,
      form: {
        email: "",
        password: "",
        confirmPassword: "",
        emailCode: "",
        termsCheckbox: "",
      },
      disabledEmail: false,
    };
  },
  components: {
    FormInput,
    FormCheckbox,
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          min: helpers.withMessage(
            this.$t("validations.password_length"),
            minLength(8)
          ),
          max: helpers.withMessage(
            this.$t("validations.password_length"),
            maxLength(20)
          ),
          // alphaNum: helpers.withMessage(
          //     this.$t('validations.password_length'),
          //   alphaNum
          // ),
        },
        confirmPassword: {
          required,
          sameAsPassword: helpers.withMessage(
            this.$t("validations.password_not_match"),
            sameAs(this.form.password)
          ),
        },
        emailCode: { required },
        termsCheckbox: { required, sameAs: sameAs(true) },
      },
    };
  },
  computed: {
    ...mapGetters({
      verified: "onboarding/verified",
    }),
  },
  methods: {
    sendEmailCode() {
      var params = new URLSearchParams();
      params.append("email", this.form.email);
      this.v$.form.email.$touch();
      if (this.form.email && !this.v$.form.email.$error) {
        this.$store.dispatch("onboarding/sendEmailCode", {
          params,
        });
        this.disabledEmail = true;
      } else {
        this.$store.dispatch("alerts/error", {
          text: this.$t("form.email_required"),
        });
      }
    },
    submitForm() {
      // this.v$.$validate();
      var params = new URLSearchParams();
      params.append("email_code", this.form.emailCode);
      params.append("password", this.form.password);
      this.v$.$validate();
      this.formSubmitted = true;
      if (!this.v$.$invalid) {
        this.$store.dispatch("onboarding/verifyEmailCode", {
          params,
        });

        this.$store.dispatch("onboarding/makeRegistration", {
          params,
        });
      }
    },
  },
})
export default class RegistrationForm extends Vue {}
